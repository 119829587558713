<template>
  <v-btn
    :loading="loggingIn"
    :block="!inToolbar"
    :large="!inToolbar"
    :style="buttonStyle"
    class="login-button portal-menu-color"
    type="submit"
    dark
    :depressed="theme.light"
  >
    {{ $t('login.button') }}
  </v-btn>
</template>

<script>
import {createHelpers} from 'vuex-map-fields'
import state from './../store/state'
const {mapFields} = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LoginSubmitButton',

  props: {
    inToolbar: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    buttonStyle () {
      let style = {
        background: this.inToolbar ? 'white' : this.theme.buttonColor || this.themeColor
      }
      if (this.inToolbar) {
        style.color = this.themeColor
      }
      return style
    }
  }
}
</script>
