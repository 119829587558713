import axios from 'axios'
import qs from 'qs'
import Raven from 'raven-js'
import queryString from 'query-string'

export default {
  requestRecoveryLink (email, locale) {
    return this.sendPostRequest('/api/user_accounts/send_reset_password_message', {
      email,
      locale
    })
  },

  sendRequest (apiUrl, filters, queries) {
    return new Promise((resolve) => {
      axios.get(apiUrl + '?' +
        filters.map(
          filter => filter.key + '=' + filter.value
        ).join('&') + '&' +
        queries.map(query => 'q[]=' + query).join('&')
        , this.requestOptions()).then((response) => {
        if (response && response.data) {
          resolve(response.data)
          return
        }
        Raven.captureMessage('API no response data', {
          level: 'error',
          extra: {
            apiUrl,
            filters,
            queries,
            response,
          }
        })
        resolve(response)
      }).catch((error) => {
        Raven.captureMessage('API error captured', {
          level: 'error',
          extra: {
            apiUrl: apiUrl,
            filters: filters,
            queries: queries,
            error: error
          }
        })
        resolve(error && error.response && error.response.data)
      })
    })
  },

  sendPostRequest (url, payload) {
    return new Promise((resolve) => {
      axios.post(url, payload, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
        resolve(err.response.data)
      })
    })
  },

  sendLoginRequest (credentials) {
    return new Promise((resolve) => {
      axios.post('/api/login/', credentials, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          resolve(err.response.data)
        })
    })
  },

  sendLogoutRequest () {
    return new Promise((resolve) => {
      axios.post('/api/logout/', {}, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          resolve(err.response.data)
        })
    })
  },

  getUserInfo () {
    const queries = []
    return new Promise(resolve => {
      return this.getItemInfo('user_accounts', 'my', queries).then(response => {
        resolve(response)
      })
    })
  },

  getSystemInfo () {
    return this.sendRequest('/gorilla/initializers/system_info', [], [])
  },

  getItemInfo (objectClass, itemId, queries) {
    return this.sendRequest('/api/' + objectClass + '/' + itemId, [], queries)
  },

  requestOptions () {

    // Use portalId directly from query props
    // It is fine and known that user can change it, makes no difference
    const queryProps = queryString.parse(location.search)

    return {
      headers: {
        'Auth-Required': true,
        'Accept-Language': true,
        'X-Requested-With': 'XMLHttpRequest',
        'X-Portal-ID': queryProps.portalId || 'aava',
        // 'X-Portal-ID': 'aava',
      }
    }
  },

  changePassword (current_password, password, password_confirmation) {
    return this.sendPostRequestFormData('/api/change_password', {
      current_password,
      password,
      password_confirmation,
    })
  },

  resetPassword (reset_token, password, password_confirmation) {
    return this.sendPostRequestFormData('/api/user_accounts/reset_password', {
      reset_token,
      password,
      password_confirmation,
    })
  },

  sendPostRequestFormData (apiUrl, data) {
    return new Promise((resolve) => {
      const formData = qs.stringify(data)
      axios.post(apiUrl,
        formData, this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response.data)
        }
        resolve({})
      }).catch((error) => {
        resolve(error.response)
      })
    })
  },
}
