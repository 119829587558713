import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginForm from '@/components/LoginForm'
import PasswordReset from '@/components/PasswordReset'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginForm',
    component: LoginForm,
  },

  {
    path: '/reset_password/:reset_token',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true,
  },
]

const router = new VueRouter({
  routes
})

export default router
