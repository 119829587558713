import en from "@/locale/en"
import fi from "@/locale/fi"
import se from "@/locale/se"
import lappsetMessages from '@/locale/lappsetMessages'
import omaMessages from '@/locale/omaMessages'

export default {
  en,
  fi,
  se,
  lappsetMessages,
  omaMessages,
}
