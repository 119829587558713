import i18n from '@/locale/i18n'
import i18nMessages from '@/locale/i18nMessages'

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

const mergeDeep = (target, source) => {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return target
}

export default {
  setLocaleMessages (instanceName) {
    // Set translations in i18n
    const messagesByLang = i18nMessages[instanceName + 'Messages'] || {}
    Object.keys(messagesByLang).forEach(lang => {
      // Not using locales array itself here as this was recently added and takes a while when it's deployed everywhere
      const combinedMessages = mergeDeep(i18n.messages[lang], messagesByLang[lang])
      i18n.setLocaleMessage(lang, combinedMessages)
    })
  }
}
