<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-container class="white pa-5" fluid>
      <v-row v-if="sent">
        <v-col>
          {{ $t('login.resetPassword.passwordChangeSuccess') }}
          <br>
          <br>
          <v-btn @click="dialog = false">OK</v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="processing" class="px-5">
        <v-progress-linear
            color="green lighten-1"
            height="15"
            indeterminate
        />
      </v-row>
      <v-row v-else>
        <!-- dummy input to prevent browser focusing global search field when auto populating user+pass -->
        <div style="position: absolute; top: -10000px;">
          <input type="text" name="dummy_username" />
          <input type="password" name="dummy_password" />
        </div>
        <v-col cols="12">
          <h2>{{ $t('login.resetPassword.enter_password') }}</h2>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="new_password"
              :label="$i18n.t('login.resetPassword.password_placeholder')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              hide-details
              @click:append="() => {
                showPassword = !showPassword
              }"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="new_password_confirmation"
              :label="$i18n.t('login.resetPassword.confirm_password_placeholder')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :clearable="false"
              outlined
              hide-details
              @click:append="() => {
                showPassword = !showPassword
              }"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            :color="this.$store.state.theme.buttonColor || this.$store.state.themeColor"
            :dark="!disabled"
            :disabled="disabled"
            :loading="saving"
            height="56"
            @click="resetPassword"
          >
            {{ $t('login.resetPassword.send_new_password') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import api from '@/store/api'
import {createHelpers} from 'vuex-map-fields'
import state from './../store/state'

const {mapFields} = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'PasswordReset',

  data () {
    return {
      sent: false,
      email: '',
      processing: false,
      new_password: '',
      new_password_confirmation: '',
      saving: false,
      showPassword: false,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
    reset_token: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    disabled () {
      return !(this.reset_token && this.new_password && this.new_password_confirmation)
    },

    userIsLoggedIn () {
      return this.userInfo && this.userInfo.id
    },
  },

  methods: {
    resetPassword () {
      this.saving = true
      api.resetPassword(this.reset_token, this.new_password, this.new_password_confirmation).then(response => {
        if (response && response.status === 'ok' && response.message) {
          const message = this.$i18n.t(response.message.replace('aava.reset_password:', 'login.resetPassword.'))
          this.$store.commit('updateSnackbar', {
            show: true,
            message: message,
            color: 'success',
          })
          // After successful password return to the login form with portalUrl,
          // so after login user is redirected to the origin app
          const path = this.$route.query.portalUrl
            ? '/?portalUrl=' +  this.$route.query.portalUrl + '&portalName=' +  this.$route.query.portalName +
              (this.$route.query.portalId ? '&portalId=' +  this.$route.query.portalId : '')
            : '/'
            this.$router.push({ path })
        } else {
          let message = 'Error'
          if (response.data && response.data.messages && response.data.messages[0]) {
            message = this.$i18n.t(response.data.messages[0].key.replace('aava.reset_password:', 'login.resetPassword.'))
          }
          this.$store.commit('updateSnackbar', {
            show: true,
            message: message,
            color: 'error',
          })
        }
        this.saving = false
      })
    },
  },
}
</script>
