<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
  >
    <v-container
      :class="'white pa-5 ' + ($store.state.instanceName + '-theme')"
      fluid
    >
      <v-row v-if="sent">
        <v-col>
          {{ $t('login.changePassword.passwordChangeSuccess') }}
          <br>
          <br>
          <v-btn @click="dialog = false">OK</v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="processing" class="px-5">
        <v-progress-linear
            color="green lighten-1"
            height="15"
            indeterminate
        />
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <h2>{{ $t('login.changePassword.passwordExpired') }}</h2>
        </v-col>
        <v-col cols="12">
          <!-- dummy input to prevent browser focusing global search field when auto populating user+pass -->
          <div style="position: absolute; top: -10000px;">
            <input type="text" name="dummy_username" />
            <input type="password" name="dummy_password" />
          </div>
          <v-text-field
              v-model="current_password"
              :label="$i18n.t('login.changePassword.oldPassword')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              hide-details
              @click:append="() => {
                showPassword = !showPassword
              }"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="password"
              :label="$i18n.t('login.changePassword.newPassword')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              hide-details
              @click:append="() => {
                showPassword = !showPassword
              }"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="password_confirmation"
              :label="$i18n.t('login.changePassword.newPasswordAgain')"
              :clearable="false"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              hide-details
              @click:append="() => {
                showPassword = !showPassword
              }"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            :color="this.$store.state.themeColor"
            :dark="!disabled"
            :disabled="disabled"
            :loading="saving"
            height="56"
            block
            @click="changePassword"
          >
            {{ $t('login.changePassword.submit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import api from '@/store/api'
import {createHelpers} from 'vuex-map-fields'
import state from './../store/state'

const {mapFields} = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'PasswordChange',

  data () {
    return {
      sent: false,
      email: '',
      processing: false,
      current_password: '',
      password: '',
      password_confirmation: '',
      saving: false,
      showPassword: false,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    disabled () {
      return !(this.current_password && this.password && this.password_confirmation)
    },

    userIsLoggedIn () {
      return this.userInfo && this.userInfo.id
    },
  },

  methods: {
    changePassword () {
      this.saving = true
      api.changePassword(this.current_password, this.password, this.password_confirmation).then(response => {
        if (response && response.status === 'ok' && response.message) {
          const message = this.$i18n.t(response.message.replace('aava:change_password', 'login.changePassword'))
          this.$store.commit('updateSnackbar', {
            show: true,
            message: message,
            color: 'success'
          })
          this.passwordExpired = false
          this.$store.dispatch('getUserInfo').then(() => {
            // If already logged in redirect back to portal
            if (this.userIsLoggedIn) {
              this.$store.dispatch('goBackToOriginPortal')
            }
          })
        } else {
          let message = 'Error'
          if (response.data && response.data.messages && response.data.messages[0]) {
            message = this.$i18n.t(response.data.messages[0].key.replace('aava:change_password', 'login.changePassword'))
          }
          this.$store.commit('updateSnackbar', {
            show: true,
            message: message,
            color: 'error'
          })
        }
        this.saving = false
      })
    },
  },
}
</script>
