<template>
  <v-app>
    <SnackbarMessage />
    <router-view/>
  </v-app>
</template>

<script>
import SnackbarMessage from './components/SnackbarMessage'

export default {
  name: 'App',

  components: {
    SnackbarMessage
  }
}
</script>

<style lang="scss">
.v-application div {
  font-family: Montserrat; /* Lappset requested this font. It's quite nice and let's use it for everyone, for now */
}
.lappset-theme {
  font-family: Montserrat;
}
.starclub-theme {
  body, .theme--light.v-card {
    color: #212121 !important; /* Finnlines requirement, do not change */
  }
  .v-input__slot {
    background: #F2F5FB !important;
  }
  .theme--light.v-btn.v-btn--outlined.v-btn--text {
    border-color: #003379;
  }
  .v-text-field--outlined, .v-text-field--solo, .v-btn {
    border-radius: 0 !important;
  }
}
</style>

<style>
.theme--light.v-card {
  color: #666;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.v-btn {
  letter-spacing: normal !important;
  text-transform: none !important;
}
</style>
