import api from './api'
import i18n from './../locale/i18n'
import moment from 'moment'

// When user has 'startpage' set, redirect to it
const loginRedirectToStartPage = (startpage) => {
  if (!startpage) { return false }
  if (startpage.startsWith('/#') || startpage.startsWith('#')) {
    window.location.href = window.location.origin + startpage
    return true
  } else {
    const base = window.location.protocol + '//' + window.location.hostname
    window.location.href = base + startpage
    return true
  }
}

export default {
  loginUser: ({ commit, state, dispatch }, { username, password, tfToken, token, googleToken }) => {
    if (!token && !googleToken && !(username && password)) {
      return
    }
    state.loggingIn = true
    let requestParameters = {}
    if (token) {
      requestParameters['authentication_token'] = token
    } else if (googleToken) {
      requestParameters['token_id'] = googleToken
    } else {
      requestParameters['name'] = username
      requestParameters['password'] = password
      if (tfToken) {
        requestParameters['tfToken'] = tfToken
      }
    }
    // Authenticate with user + pass
    api.sendLoginRequest(requestParameters).then(loginResponse => {
      if (loginResponse && loginResponse.status === 'tw_token_needed') {
        // Two Factor Token required
        state.askTFToken = true
        state.loggingIn = false
      } else if (loginResponse && loginResponse.status === 'ok' && loginResponse.item && loginResponse.item.id) {
        // Get user info
        api.getUserInfo().then(response => {
          if (response && response.item && response.item.password_change_date &&
            moment(response.item.password_change_date).isValid() && moment(response.item.password_change_date).isBefore()
          ) {
            state.passwordExpired = true
          } else if (response && response.item) {
            state.userInfo = response.item
            state.username = null
            state.password = null
            state.tfToken = null
            dispatch('goBackToOriginPortal')
          } else {
            commit('updateSnackbar', {
              show: true,
              message: 'User data error',
              color: 'error'
            })
          }
          state.loggingIn = false
          state.askTFToken = false
        })
      } else {
        commit('updateSnackbar', {
          show: true,
          message: i18n.t('login.messages.invalid_credentials'),
          color: 'error'
        })
        state.loggingIn = false
      }
    })
  },

  goBackToOriginPortal: ({ state }) => {
    const referrer = window.location.origin + window.location.pathname
    if (
        // When 'startpage' is set, go there
        loginRedirectToStartPage(state.userInfo.startpage)
    ) {
      return
    } else if (state.referrer && state.referrer !== referrer) {
      state.redirectingBack = true
      window.location.replace(state.referrer)
    }
  },

  initGoogleAuth: ({ state, dispatch }) => {
    return new Promise(resolve => {
      const clientId = state.systemInfo?.auth?.google?.key
      if (!clientId || window.google) return resolve()

      const setupCallback = () => {
        window.google.accounts.id.initialize({
          client_id: "6221751428-fgfbn8pi243pomj79t1akespfn6dao3c.apps.googleusercontent.com",
          callback: ({ credential }) => dispatch('loginUser', { googleToken: credential }),
        })
        state.googleAuth = true
        resolve()
      }

      state.googleAuth = false
      const script = document.createElement("script")
      script.src = "https://accounts.google.com/gsi/client"
      script.onload = setupCallback
      script.async = true
      script.defer = true
      document.querySelector("head")?.appendChild(script)
    })
  },

  getSystemInfo: ({ state }) => {
    return new Promise(resolve => {
      api.getSystemInfo().then(response => {
        if (response.data) {
          state.systemInfo = response.data
        }
        resolve(response.data)
      })
    })
  },

  getUserInfo: ({ state }) => {
    state.loadingUserInfo = true
    return new Promise(resolve => {
      state.checkingUserSession = true
      api.getUserInfo().then(response => {
        if (response && response.item) {
          state.userInfo = response.item
        }
        state.checkingUserSession = false
        state.loadingUserInfo = false
        resolve(true)
      })
    })
  },

  logoutUser: ({ state, commit }) => {
    return new Promise(resolve => {
      state.loggingOut = true
      api.sendLogoutRequest().then(response => {
        state.loggingOut = false
        if (response && response.status === 'ok') {
          state.userInfo = {}
          resolve(true)
        } else {
          commit('updateSnackbar', {
            show: true,
            message: 'Logout error',
            color: 'error'
          })
          // TODO - log to Sentry, should not happen
          resolve(false)
        }
      })
    })
  }
}
