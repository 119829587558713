export default {
  login: {
    title: 'Kirjaudu sisään',
    'Aava Portal_title': 'Aava Portaali',
    text: 'Ole hyvä ja kirjaudu sisään',
    username: 'Käyttäjätunnus',
    password: 'Salasana',
    button: 'Kirjaudu',
    cancel: 'Keskeytä',
    logoutButton: 'Kirjaudu ulos',
    loggingOut: 'Kirjaudu ulos',
    loggedInAs: 'Käyttäjä',
    enterTfToken: 'Anna saamasi vahvistuskoodi',
    tfToken: 'Vahvistuskoodi',
    forgotPasswordLink: 'Unohditko salasanasi?',
    forgotPassword: {
      title: 'Tilaa uusi salasana',
      email: 'Email-osoitteesi',
      submit: 'Lähetä salasanan uusintapyyntö',
      cancel: 'Keskeytä',
      message: 'Salasanan uusintalinkki lähetety antamaasi sähköpostiosoitteeseen. Mikäli sähköposti ei tule perille muutaman minuutin sisällä, niin varmista, että antamasi sähköpostiosoite oli oikein kirjoitettu.',
    },
    changePassword: {
      title: 'Vaihda salasana',
      oldPassword: 'Nykyinen salasanasi',
      newPassword: 'Uusi salasanasi',
      newPasswordAgain: 'Vahvista uusi salasanasi',
      submit: 'Lähetä',
      fields_are_mandatory: 'Kaikki kentät tulee täyttää',
      change_is_mandatory: 'Uusi salasana ei saa olla sama uin vanha salasana',
      not_authenticated: 'Vaihtaaksesi salasanasi kirjaudu ensin sisään',
      invalid_password: 'Väärä salasana',
      passwords_dont_match: 'Salasana ja salasanan vahvistus eivät ole samoja',
      password_change_failed: 'Salasanan vaihto epäonnistui. Salasanan tulee olla vähintään 8 merkkiä pitkä',
      password_change_success: 'Salasanan vaihto onnistui',
      password_changed: 'Salasanan vaihto onnistui',
      passwordExpired: 'Salasanasi on vanhentunut. Aseta uusi salasana',
    },
    resetPassword: {
      enter_password: 'Kirjoita uusi salasana',
      send_new_password: 'Vaihda salasana',
      password: 'Salasana',
      password_placeholder: 'Uusi salasana',
      confirm_password_placeholder: 'Vahvista uusi salasana',
      confirm_password: 'Vahvista uusi salasana',
      invalid_password: 'Salasana ei kelpaa',
      password_change_failed: 'Salasanan vaihtolinkki on virheellinen, vanhentunut tai käytetty',
      password_reset_link_already_used: 'Salasanan vaihtolinkki on virheellinen, vanhentunut tai käytetty',
      password_reset_link_has_expired: 'Salasanan vaihtolinkki on virheellinen, vanhentunut tai käytetty',
      passwords_dont_match: 'Salasana ja salasanan vahvistus eivät ole samoja',
      password_changed: 'Salasanan vaihto onnistui',
    },
    messages: {
      invalid_credentials: 'Virhe kirjautumistiedoissa',
      connection_problem: 'Virhe yhteydessä tai kirjautumisessa',
      frozen: 'Liian monta virheellistä koodia. Tunnus lukittu viideksi minuutiksi',
    },
  },
}
