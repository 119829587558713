<template>
  <v-btn
    :loading="loggingIn"
    block
    class="login-button portal-menu-color elevation-0"
    color="grey lighten-3"
    @click="cancelLogin"
  >
    {{ $t('login.cancel') }}
  </v-btn>
</template>

<script>
import loginMethods from './LoginMethods'
import {createHelpers} from 'vuex-map-fields'
import state from './../store/state'
const {mapFields} = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LoginCancelButton',

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    ...loginMethods
  }
}
</script>
