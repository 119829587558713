<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    horizontal
    top
  >
    <div v-html="snackbar.message"/>
    <v-btn
      text
      icon
      color="white"
      @click="snackbar.show = false"
    >
      <v-icon>fa-times</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>

const delaySeconds = 3

export default {
  name: 'SnackbarMessage',

  data () {
    return {
      timeout: delaySeconds * 1000
    }
  },

  computed: {
    snackbar: {
      get () {
        let snackbar = this.$store.state.snackbar
        if (!snackbar.timeout) {
          snackbar.timeout = delaySeconds * 1000
        }
        return snackbar
      },

      set (value) {
        this.$store.commit('updateSnackbar', value)
      }
    }
  }
}
</script>
