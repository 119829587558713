export default {
  portalNameTranslated () {
    // Not passed by url parameter
    if (!this.portalName) {
      return this.$t('login.title')
    }
    // Try to translate passed portal name through instance locale
    if (this.$te('login.' + this.portalName + '_title')) {
      return this.$t('login.' + this.portalName + '_title')
    }
    // Return as is
    return this.portalName
  },
}
