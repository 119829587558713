// Get instance through url, to get theme for the instance
export default (instanceThemes) => {
  let selectedInstanceName = ''
  Object.keys(instanceThemes).forEach(instanceName => {
    instanceThemes[instanceName].domainIncludes.forEach(searchInDomain => {
      if (location.host.includes(searchInDomain)) {
        selectedInstanceName = instanceName
      }
    })
  })
  return selectedInstanceName || 'default'
}
