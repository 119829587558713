export default {
  en: {
    login: {
      title: 'Aava Portal',
    },
  },
  fi: {
    login: {
      title: 'Aava Portaali',
    },
  },
  se: {
    login: {
      title: 'Aava Portal',
    },
  },
}
