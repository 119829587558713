export default {
  theme: {},
  instanceName: '',
  action: 'login',
  loggingIn: false,
  loggingOut: false,
  logoUrl: null,
  portalName: '',
  portalId: '',
  themeColor: '#455A64',
  logoBgColor: null,
  checkingUserSession: false,
  referrer: null,
  redirectingBack: false,
  loadingUserInfo: false,
  askTFToken: false,
  userInfo: {},
  locale: 'fi',
  username: null,
  password: null,
  tfToken: null,
  passwordExpired: false,
  testMode: false,
  testToken: '',
  languages: ['en', 'fi', 'se'],
  portalID: null, // TODO ?
  alertSnackbar: false,
  queryProps: {},
  showPasswordRecoveryForm: false,
  snackbar: {
    message: null,
    timeout: 2000,
    color: 'info'
  },
  googleAuth: false,
  systemInfo: {},
}
