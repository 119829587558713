import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store/store'
import VueI18n from 'vue-i18n'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'

if (!['dev.test', 'localhost_'].includes(window.location.host)) {
  Raven.config('https://765aedac07b74ebf91b1c2211d0f1b48@sentry.aavasoftware.com/31',
    {
      environment: 'production'
    })
    .addPlugin(RavenVue, Vue)
    .install()
}

import VueBarcodeScanner from 'vue-barcode-scanner'
import i18n from './locale/i18n'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueBarcodeScanner, {
  callbackAfterTimeout: true,
  sensitivity: 70,
})
Vue.use(VueI18n)

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
