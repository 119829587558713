
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import i18nMessages from './i18nMessages'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'fi', // set locale
  messages: i18nMessages, // set locale messages
})
