import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from './../locale/i18n'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'fa',
  },
});
