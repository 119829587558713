export default {
  login: {
    title: 'Logga in',
    text: 'Logga in',
    username: 'Användarnamn',
    password: 'Lösenord',
    button: 'Logga in',
    cancel: 'Avbryt',
    logoutButton: 'Utloggning',
    loggingOut: 'Logga ut',
    loggedInAs: 'Inloggad som',
    enterTfToken: 'Ange verifikationstecken',
    tfToken: 'Verifikationstecken',
    forgotPasswordLink: 'Jag har glömt mitt lösenord?',
    forgotPassword: {
      title: 'Beställ nytt lösenord',
      email: 'Din mejladress',
      submit: 'Skicka lösenordsförfrågan',
      cancel: 'Avbryt',
      message: 'Länken skickad för återställning av lösenord till din e-postadress. Om du inte får e-postmeddelandet på några minuter, kontrollera att du skrev e-postadressen korrekt.',
    },
    changePassword: {
      title: 'Byt lösenord',
      oldPassword: 'Ditt nuvarande lösenord',
      newPassword: 'Nytt lösenord',
      newPasswordAgain: 'Bekräfta nytt lösenord',
      submit: 'Spara',
      fields_are_mandatory: 'Alla rader måste fyllas i',
      change_is_mandatory: 'Nytt lösenord måste skilja sig från det gamla lösenordet',
      not_authenticated: 'Du måste vara inloggad innan du byter lösenord',
      invalid_password: 'Fel lösenord',
      passwords_dont_match: 'Lösenordet och bekräftelsen överensstämmer inte',
      password_change_failed: 'Ändring av lösenord misslyckades. Lösenordet måste innehålla minst 8 tecken',
      password_change_success: 'Lösenordet är bytt',
      password_changed: 'Lösenordet är bytt',
      passwordExpired: 'Ditt lösenord har gått ut. Skapa ett nytt lösenord',
    },
    resetPassword: {
      enter_password: 'Enter new password',
      send_new_password: 'Change password',
      password: 'Password',
      password_placeholder: 'Enter new password',
      confirm_password_placeholder: 'Retype the new password',
      confirm_password: 'Confirm password',
      invalid_password: 'Invalid password',
      password_change_failed: 'Password reset link is invalid, expired or already used',
      password_reset_link_already_used: 'Password reset link is invalid, expired or already used',
      password_reset_link_has_expired: 'Password reset link is invalid, expired or already used',
      password_changed: 'Lösenordet är bytt',
      passwords_dont_match: 'Lösenordet och bekräftelsen överensstämmer inte',
    },
    messages: {
      invalid_credentials: 'Ogiltiga uppgifter',
      connection_problem: 'Fel vid anslutning eller inloggning',
      frozen: 'För många felaktiga försök, kontot låst i fem minuter',
    },
  },
}
