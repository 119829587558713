<template>
  <v-dialog
    v-model="dialog"
    width="600"
    overlay-opacity="1"
    overlay-color="grey"
  >
    <v-container
      :class="'white pa-5 ' + ($store.state.instanceName + '-theme')"
      fluid
    >
      <v-row v-if="sent">
        <v-col>
          {{ $t('login.forgotPassword.message') }}
          <br>
          <br>
          <v-btn @click="dialog = false">OK</v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="processing" class="px-5">
        <v-progress-linear
            color="green lighten-1"
            height="15"
            indeterminate
        />
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <h2>{{ $t('login.forgotPassword.title') }}</h2>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="email"
            :label="$t('login.forgotPassword.email')"
            outlined
            autofocus
            hide-details
            @keyup.enter="requestRecoveryLink()"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            :color="this.$store.state.theme.buttonColor || this.$store.state.themeColor"
            height="56"
            block
            dark
            @click="requestRecoveryLink()"
          >
            {{ $t('login.forgotPassword.submit') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            text
            @click="dialog = false"
          >
            {{ $t('login.forgotPassword.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import api from '@/store/api'
import {mapFields} from 'vuex-map-fields'

export default {
  name: 'PasswordRecovery',

  data () {
    return {
      sent: false,
      email: '',
      processing: false,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapFields(['locale']),

    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    requestRecoveryLink () {
      if (!this.email) {
        return
      }
      this.processing = true
      api.requestRecoveryLink(this.email, this.locale).then(() => {
        this.sent = true
        this.processing = false
      })
    },
  },
}
</script>
