<template>
  <div class="google-container">
    <button ref="googleLogin"></button>
  </div>
</template>

<script>
export default {
  name: 'GoogleButton',

  mounted () {
    window.google.accounts.id.renderButton(this.$refs.googleLogin, { width: 250 })
  }
}
</script>

<style scoped>
/* renderButton can be somewhat glitchy. Try to work around flicker and centering issues
   by reserving space and transforms. */
.google-container {
  width: 100%;
  margin-left: 50%;
  min-height: 80px;
  transform: translate(-50%, 0%);
}
</style>
