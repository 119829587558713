export default {
  login: {
    title: 'Login',
    'Aava Portal_title': 'Aava Portal',
    text: 'Please log in',
    username: 'Username',
    password: 'Password',
    button: 'Login',
    cancel: 'Cancel',
    logoutButton: 'Logout',
    loggingOut: 'Logging out',
    loggedInAs: 'Logged in as',
    enterTfToken: 'Enter verification token',
    tfToken: 'Verification token',
    forgotPasswordLink: 'Forgot your password?',
    forgotPassword: {
      title: 'Order new password',
      email: 'Your email address',
      submit: 'Send password request',
      cancel: 'Cancel',
      message: 'Sent the password reset link to you email address. If you do not receive the mail in a few minutes make sure you wrote the email address correctly.',
    },
    changePassword: {
      title: 'Change password',
      oldPassword: 'Your current password',
      newPassword: 'New password',
      newPasswordAgain: 'Confirm new password',
      submit: 'Submit',
      fields_are_mandatory: 'All fields must be filled',
      change_is_mandatory: 'New password must be different from the old password',
      not_authenticated: 'You need to be logged in before changing the password',
      invalid_password: 'Wrong password',
      passwords_dont_match: 'Password and password confirmation do not match',
      password_change_failed: 'Changing password failed. Password must be at least 8 characters long',
      password_change_success: 'Password changed successfully',
      password_changed: 'Password changed successfully',
      passwordExpired: 'Your password has expired. Set a new password',
    },
    resetPassword: {
      enter_password: 'Enter new password',
      send_new_password: 'Change password',
      password: 'Password',
      password_placeholder: 'Enter new password',
      confirm_password_placeholder: 'Retype the new password',
      confirm_password: 'Confirm password',
      invalid_password: 'Invalid password',
      password_change_failed: 'Password reset link is invalid, expired or already used',
      password_reset_link_already_used: 'Password reset link is invalid, expired or already used',
      password_reset_link_has_expired: 'Password reset link is invalid, expired or already used',
      passwords_dont_match: 'Password and password confirmation do not match',
      password_changed: 'Password changed successfully',
    },
    messages: {
      invalid_credentials: 'Invalid credentials',
      connection_problem: 'Error in connection or login',
      frozen: 'Too many incorrect verification codes, account locked for five minutes',
    }
  },
}
