var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',{class:'white pa-5 ' + (_vm.$store.state.instanceName + '-theme'),attrs:{"fluid":""}},[(_vm.sent)?_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('login.changePassword.passwordChangeSuccess'))+" "),_c('br'),_c('br'),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("OK")])],1)],1):(_vm.processing)?_c('v-row',{staticClass:"px-5"},[_c('v-progress-linear',{attrs:{"color":"green lighten-1","height":"15","indeterminate":""}})],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('login.changePassword.passwordExpired')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"position":"absolute","top":"-10000px"}},[_c('input',{attrs:{"type":"text","name":"dummy_username"}}),_c('input',{attrs:{"type":"password","name":"dummy_password"}})]),_c('v-text-field',{attrs:{"label":_vm.$i18n.t('login.changePassword.oldPassword'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","hide-details":""},on:{"click:append":() => {
              _vm.showPassword = !_vm.showPassword
            }},model:{value:(_vm.current_password),callback:function ($$v) {_vm.current_password=$$v},expression:"current_password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.t('login.changePassword.newPassword'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","hide-details":""},on:{"click:append":() => {
              _vm.showPassword = !_vm.showPassword
            }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.t('login.changePassword.newPasswordAgain'),"clearable":false,"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","hide-details":""},on:{"click:append":() => {
              _vm.showPassword = !_vm.showPassword
            }},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":this.$store.state.themeColor,"dark":!_vm.disabled,"disabled":_vm.disabled,"loading":_vm.saving,"height":"56","block":""},on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$t('login.changePassword.submit'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }