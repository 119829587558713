export default {
  cancelLogin () {
    this.askTFToken = false
  },

  login () {
    // this.askTFToken = true
    // return
    this.$store.dispatch('loginUser', {
      username: this.username,
      password: this.password,
      tfToken: this.tfToken,
    })
  },

  logout () {
    this.$store.dispatch('logoutUser')
  },

  simulate () {
    this.authenticateUsingToken(this.testToken)
  },

  authenticateUsingToken (token) {
    if (!token || token.length < 6) { return }
    this.$store.dispatch(
      'loginUser',
      { token: token },
      { root: true }
    )
  },

  showHelp () {
    this.dialog = true
  },

  changeLocale (lang) {
    this.$store.dispatch('changeLocale', lang)
  },
}
