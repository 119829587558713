import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import { getField, updateField } from 'vuex-map-fields'
import state from './state'

import loginActions from './loginActions'

Vue.use(VueI18n)
Vue.use(Vuex)

export default new Vuex.Store({
  state () {
    return state
  },

  getters: {
    getField
  },

  actions: {
    ...loginActions,

    changeLocale ({ state }, language) {
      if (state.languages.indexOf(language) > -1) {
        state.locale = language
      }
    },
  },

  mutations: {
    updateField,

    updateSnackbar (state, value) {
      /*
       * Have to hide snackbar in case there is message already shown
       * Otherwise previous message timeout affects new message
       */
      state.snackbar = {
        show: false
      }
      Vue.nextTick(() => {
        state.snackbar = value
      })
    }
  }
})
