export default {
  en: {
    login: {
      PlayCare_title: 'PlayCare Service Portal',
      username: 'E-mail',
    },
  },
  fi: {
    login: {
      PlayCare_title: 'PlayCare Huoltoportaali',
      username: 'Sähköposti',
      button: 'Kirjaudu sisään',
    },
  },
  se: {
    login: {
      PlayCare_title: 'PlayCare Serviceportalen',
      username: 'E-post',
    },
  },
}
